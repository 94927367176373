<template>
  <main class="page">
    <section class="section section--after-payment">
      <div class="text-center">
        <div class="after-payment__content">
          <h2 class="title title-md">{{ $t('web.fail-payment_title') }}</h2>
          <p class="text text-md py-3">
            {{ $t('web.fail-payment_description') }}
          </p>
          <div>
            <c-link
              class="text text-md"
              :to="{ name: $ROUTER.NAME.PRICES }"
              nowrap
              :label="$t('web.fail-payment_go-to-prices')"
            />
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import CLink from '@/components/Link'
export default {
  name: 'Failure',
  components: {
    CLink,
  },
}
</script>

<style lang="scss" scoped>
@import '../index';
</style>
